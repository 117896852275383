import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'

import Card from 'react-bootstrap/Card'
import CardGroup from 'react-bootstrap/CardGroup'
import Button from 'react-bootstrap/Button'

import Layout from '../components/layout'

const PHONE_NUMBER = '425-466-8564'
const CONTACT_EMAIL = 'contact@4groupmath.com'
const ORDERS_EMAIL = 'orders@4groupmath.com'
const LYNN_EMAIL = 'lynn@4groupmath.com'

export default class Contact extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const metaDescription = `Get in touch with ${siteTitle}. Contact: ${PHONE_NUMBER} - ${CONTACT_EMAIL}`
    return (
      <Layout pageTitle="Contact" description={metaDescription}>
        <h1>Contact Us</h1>

        <CardGroup>
          <Card>
            <Button href={`tel:${PHONE_NUMBER}`}>
              <i className="fa fa-phone"></i>
            </Button>
            <Card.Body>
              <a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a>
            </Card.Body>
          </Card>
          <Card>
            <Button href={`mailto:${CONTACT_EMAIL}`}>
              <i className="fa fa-envelope"></i>
            </Button>
            <Card.Body>
              <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>
            </Card.Body>
          </Card>
        </CardGroup>

        <hr />

        <h2>Customer Service</h2>
        <p>Need help with one of our products?</p>
        <Button as={Link} to="/customer-service/">
          Customer Service
        </Button>

        <hr />

        <h2>Orders</h2>
        <p>For shipping, returns, and exchange inquires:</p>
        <Button href={`mailto:${ORDERS_EMAIL}`}>
          <i className="fa fa-envelope"></i> - {ORDERS_EMAIL}
        </Button>

        <hr />

        <h2>Educators</h2>
        <p>For educator inquires, please contact Lynn Kuske:</p>
        <Button href={`mailto:${LYNN_EMAIL}`}>
          <i className="fa fa-envelope"></i> - {LYNN_EMAIL}
        </Button>
      </Layout>
    )
  }
}

export const Query = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
  }
`
